<template>
  <bulma-table
    class="table is-bordered is-striped is-narrow is-fullwidth"
    :columns="columns"
    :page-index="filter.pageIndex"
    :page-size="filter.pageSize"
    :total-rows="totalRows"
    :is-loading="isTableLoading"
    :sort-column="filter.sortColumn"
    :sort-order="filter.sortOrder"
    :show-header="true"
    @pageChanged="onPageChange"
    @sort="sort">
    <tr
      v-for="(message, index) in messages"
      :key="message.messageId"
      @click="highlightSelected(index, $event)">
      <td>
        {{ message.messageTypeDescription }}
        <span v-if="message.messageDirection === 0"
          class="tooltip is-tooltip-topleft"
          data-tooltip="Inbound">
          <i class="mdi mdi-arrow-down-thick has-text-success" />
        </span>
        <span v-else
          class="tooltip is-tooltip-topleft"
          data-tooltip="Outbound">
          <i class="mdi mdi-arrow-up-thick has-text-danger" />
        </span>
      </td>
      <td v-if="messageType !== 'EstImage'">
        {{ message.qdeStatus }}
      </td>
      <td v-if="messageType !== 'EstImage'">
        {{ message.messageNo }}
      </td>
      <td class="has-text-right">
        {{ message.quoteNo }}
      </td>
      <td>
        {{ $filters.formatDateTimeLocale(message.createdDate, $userInfo.locale) }}
      </td>
      <td class="is-content-width has-text-centered">
        <a
          class="button is-primary is-small is-inverted tooltip is-tooltip-topright"
          data-tooltip="Click to view"
          @click="viewXml(message.messageId)">
          <span class="icon is-medium">
            <i class="mdi mdi-file-find mdi-24px" />
          </span>
        </a>
      </td>
    </tr>
    <template slot="empty">
      <section class="section">
        <div class="content has-text-grey has-text-centered">
          <span icon="icon is-large">
            <i class="mdi mdi-48px mdi-emoticon-sad" />
          </span>
          <p>Nothing</p>
        </div>
      </section>
    </template>
  </bulma-table>
</template>

<script>
import BulmaTable from '@/components/BulmaTable'
import XmlViewerService from './XmlViewerService'
import Guid from '@/components/Guid'
import { Columns, EstimageColumns } from './columns'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'

export default {
  name: 'XmlMessageList',
  components: {
    BulmaTable
  },
  mixins: [DateTimeFiltersMixin],
  props: {
    quoteId: {
      type: String,
      default: Guid.empty()
    },
    messageType: {
      type: String,
      default: ''
    },
    pageSize: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      messages: null,
      filter: {
        quoteId: this.quoteId,
        webServiceType: this.messageType,
        messageTypeId: 0,
        statusId: 0,
        sortColumn: 'CreatedDate',
        sortOrder: 'desc',
        pageIndex: 1,
        pageSize: this.pageSize
      },
      totalRows: 0,
      selectedRow: null
    }
  },
  computed: {
    columns() {
      if (this.messageType === 'EstImage') {
        return EstimageColumns
      }
      return Columns
    }
  },
  created() {
    this.getMessages()
  },
  mounted() {},
  methods: {
    async getMessages() {
      try {
        this.isTableLoading = true
        this.messages = await XmlViewerService.getEntitySummaries(this.filter)
        this.totalRows = this.messages.length > 0 ? this.messages[0].totalRows : 0
      } catch (e) {
        this.$emit('error')
      } finally {
        this.isTableLoading = false
      }
    },
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.getMessages()
    },
    sort(name, order) {},
    async viewXml(messageId) {
      this.$emit('view', messageId)
    }
  }
}
</script>
